let scrollableElement = document.querySelector('#feed')

scrollableElement.addEventListener('wheel', (ev) => {
  if (!scrollableElement.classList.contains('opened-popup')) {
    if (!ev.shiftKey) {
      ev.preventDefault()
      scrollableElement.scrollLeft += ev.deltaY + ev.deltaX
    }
  }
})
