const pageFeed = document.querySelector('#feed')
const animateBlocks = document.querySelectorAll('.animate-init')

function checkPosition() {
  if (animateBlocks.length > 0) {
    animateBlocks.forEach((animateBlock) => {
      const rect = animateBlock.getBoundingClientRect()
      const threshold = window.innerWidth * 0.7
      if (rect.left <= threshold) {
        animateBlock.classList.add('animate-start')
      // } else {
      //   animateBlock.classList.remove('animate-start')
      }
    })
  }
}

pageFeed.addEventListener('scroll', checkPosition)
window.addEventListener('resize', checkPosition)

window.addEventListener('load', function () {
  checkPosition()
})