const body = document.querySelector('#feed')

document.addEventListener('click', function (e) {
  const target = e.target
  if (target.classList.contains('opened-popup')) {
    closeWindow()
  } else if (target.closest('svg.close-popup')) {
    closeWindow()
  } else if (target.closest('.popup-button')) {
    let popbtn = target.closest('.popup-button')
    let data = popbtn.getAttribute('data-popup')
    if (data) {
      let section = target.closest('section')
      let window = section.querySelector(`.popup-window[data-popup="${data}"]`)
      window.classList.add('opened')
      body.classList.add('opened-popup')
    }
  }
})

let closeWindow = function () {
  body.classList.remove('opened-popup')
  let openedWindow = document.querySelector('.popup-window.opened')
  if (openedWindow) {
    openedWindow.classList.remove('opened')
  }
}
