let scrollableElement = document.querySelector('#feed');

window.onload = function () {
  if (!window.location.hash) {
    if (window.innerWidth <= 992) {
      const block = document.getElementById('mobile-start');
      if (block) {
        const blockPositionX = block.getBoundingClientRect().left + scrollableElement.scrollLeft;
        const screenWidth = window.innerWidth;

        const scrollPositionX = blockPositionX + block.offsetWidth / 2 - screenWidth / 2;

        scrollableElement.scrollTo({ left: scrollPositionX, behavior: 'smooth' });
      }
    }
  }
};